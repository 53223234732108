import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Home" />
    <main>
        <div className="standard-container mt6">
            <h2 className="uk-align-center mt6">
            Emirates Mars Mission Website and Mobile Application Privacy Policy
            </h2>
            <p className="pt4">
            Thank you for using the Emirates Mars Mission website. We have a firm commitment to the protection and security of your privacy and personal information, and this Privacy Policy describes the privacy practices for the services provided by virtue of our site. We set out the information that we collect, how we collect it, how it is stored, and how it is safeguarded. 
            </p>
            <h2>
            Definitions
            </h2>
            <p>
            Wherever used in this Policy, the following terms denote the below meanings:
            </p>
            <ol>
                <li>
                “We,” “our,” “us,” or “EMM” or  here refers to Emirates Mars Mission by Mohammed bin Rashid Space Centre. <br/> “You,” or “yours” refers to you as a user of our website. 
                </li>
                <li>
                “Data Controller”  means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.
                </li>
                <li>
                “Data Processor” means any natural or legal person who processes the data on behalf of the Data Controller.
                </li>
                <li>
                “Data Subject” is any person who is using our Service and is the subject of Personal Data.
                </li>
                <li>
                “GDPR” is the General Data Protection Regulation Act.
                </li>
                <li>
                “MBRSC” means Mohammed Bin Rashid Space Center, a government agency founded in 2006 and home to UAE’s national space program. You can contact MBRSC using our contact information found here. 
                </li>
                <li>
                “Personal Data” means any data that is related to an identified or identifiable person which can be used to, or can potentially be used to, identify a data subject.
                </li>
                <li>
                “SDC” means Science Data Center, which includes the SDC website and underlying infrastructure used for processing and storage and archival of science data.
                </li>
                <li>
                “Website” or “Site” refers to this website, www.emiratesmarsmission.ae 
                </li>
                <li>
                “Mobile Application” refers to the Hope Probe mobile application.
                </li>
            </ol>
            <h2>
            1. What Personal Data We Collect
            </h2>
            <p>
            When you visit the Site or mobile application, we automatically collect certain information about your device. This includes your web browser, IP address, time zone, date and time of website access, username,mobile types, mobile operating system through some of the cookies installed. As you browse the site or mobile application, we collect information about the webpages you view and usage of mobile application. We use this information to understand how you use our site and mobile application in an effort to make our website and mobile application more useful to you. We also utilize this information for security management. There will be no attempts to identify users or their usage habits except for law enforcement.
                <br/>
            When you create an account on our Site, you are required to provide certain information such as your first and last name, an email address, and the name of the institution you are affiliated with. We do not collect other sensitive information such as your nationality, political views, or health conditions. 
            </p>
            <p>
            We may also collect other information depending on how you contact us:
            </p>
            <p>
            a.	Communications from you
            <br/>
            If you get in touch with us, you might provide us with your name, contact details, or other personal information as a part of your message.
            </p>
            <p>
            c.	Email campaigns
            <br/>
            We may contact you from time to time to share information about new features on our website and mobile application. We will not contact you unless you have provided us with your explicit consent. 
            </p>
            <p>
            b.	Support
            <br/>
            When you request certain support services, whether by telephone or email, you may provide us with certain personal information in order to receive assistance. 
            </p>
            <p>
            Our website is dynamic and innovative, and as such, we will always seek to improve it. In the event that we introduce new features that result in the collection of new information or the different usage of that information, we will inform you by updating this privacy policy. 
            </p>
            <h2>
            2. How We Use the Information We Collect
            </h2>
            <p>
            We strive to ensure that there is absolute transparency between us and users of our Site. We use the personal data described above to:
            </p>
            <p>
            a.	Allow you to benefit from our Site
            <br/>
            We process your data in order to provide you access to the science data available on the EMM SDC. Some of the data we collect allows us to provide this data in a way that is tailored to your interests. 
            </p>
            <p>
            b.	Communicate with you <br/>
            We may use the personal data provided to us with communicate any updates to our Site with you. Such updates may include informing you of public data releases or any new data available on our site.
            </p>
            <p>
            c.	Detect, prevent, and address technical issues <br/>
            The data we collect allows us to keep track of any technical issues. We also use this data to conduct research and development to improve our Site or mobile application and your experience on our Site and mobile application.
            </p>
            <h2>
            3. Protecting Your Information 
            </h2>
            <p>
            It is our utmost priority that the information entrusted to us is protected. We secure your information using international protection standards and implement various security measures to ensure the safety of your data. 
            <br/>
            We ensure that all access to the data on our site is protected by passwords accessible only to those who are required to have such access. All data is enabled with multi-factor authentication. All data on our servers are pseudonymize and encrypt it to maintain anonymity.
            </p>
            <h2>
            4. Retaining Your Information
            </h2>
            <p>
            Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.
            </p>
            <p>
            We will retain your personal data as follows:
            </p>
            <p>
            a.	Your name, email address, and the institution you are affiliated with will be retained for the time that you have an account on our Site. Such information shall be permanently removed upon your deletion of your account. 
            </p>
            <p>
            b.	Data on your web browser, IP address, time zone, date and time of website access will be retained for a period of X from EVENT.
            </p>
            <p>
            In some cases, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.
            </p>
            <h2>
            5. Our Data Processing Principles
            </h2>
            <p>
            Our principles for processing personal data are:
            </p>
            <ul>
                <li>
                Fairness and lawfulness. When we collect personal data and process it, Data Subjects’ individual rights must be protected. All data that is personal must be collected and processed in a fair and legal manner.
                </li>
                <li>
                Restricted to a specific purpose. The Data Subject’s personal data must be processed only for specific purposes.
                </li>
                <li>
                Transparency. The Data Subject must be informed of how his/her data is being collected, processed and used.
                </li>
            </ul>
            <h2>
            6. Cookie Usage
            </h2>
            <p>
            Cookies are a small text file that is stored on a user’s computer that can be temporary and persistent. They are used to store preferences for a session of usage of a website and mobile application. There are two types of cookies: session and persistent. Session cookies are temporarily stored when the web browser is running. Persistent cookies are stored on a user’s computer for a longer time. Cookies are for a specific site and no other website can request this specific cookie.
            </p>
            <p>
            The EMM website uses cookies for technical usage such as session cookies that allows a user preference on the website which provides a better navigation experience. Persistent cookies are used for user preferences on the website. You may turn those cookies off using your web browser, but it will affect some functionalities of the SDC website.
            </p>
            <h2>
            7. Disclosure to Third Parties
            </h2>
            <p>
            We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our Website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. 
            </p>
            <p>
            We may also release your information when we believe release is appropriate to comply with the law, to enforce our Website’s policies, or protect our rights or others’ rights, property, or safety. However, non-personally identifiable visitor’s information may be provided to other parties for marketing, advertising, or other uses. 
            </p>
            <h2>
            8. Data protection rights
            </h2>
            <p>
            If you are a resident of the European Economic Area (EEA), you have certain data protection rights. We have summarized below the rights that you have under GDPR. 
            </p>
            <p>
            Your principal rights under data protection law are:
            </p>
            <p>
            a.	The right to access;
            <br/>
            You have the right to confirm as to whether or not we process your personal data and, where we do, access to the personal data, together with certain additional information. That additional information includes details of the purposes of the processing, the categories of personal data concerned and the recipients of the personal data. Providing the rights and freedoms of others are not affected, we will supply to you a copy of your personal data.  
            </p>
            <p>
            b.	The right to rectification;
            <br/>
            You have the right to have any inaccurate personal data about you rectified and, taking into account the purposes of the processing, to have any incomplete personal data about you completed.
            </p>
            <p>
            c.	The right to erasure;
                <br/>
            In some circumstances you have the right to the erasure of your personal data without undue delay. Those circumstances include: the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed; you withdraw consent to consent-based processing; you object to the processing under certain rules of applicable data protection law; the processing is for direct marketing purposes; and the personal data have been unlawfully processed. However, there are exclusions of the right to erasure. The general exclusions include where processing is necessary such as for compliance with a legal obligation; or for the establishment, exercise or defense of legal claims].
            </p>
            <p>
            d.	The right to restrict processing;
            <br/>
            In some circumstances you have the right to restrict the processing of your personal data. Those circumstances are: you contest the accuracy of the personal data; processing is unlawful but you oppose erasure; we no longer need the personal data for the purposes of our processing, but you require personal data for the establishment, exercise or defense of legal claims; and you have objected to processing, pending the verification of that objection. Where processing has been restricted on this basis, we may continue to store your personal data. However, we will only otherwise process it: with your consent; for the establishment, exercise or defense of legal claims; for the protection of the rights of another natural or legal person; or for reasons of important public interest.
            </p>
            <p>
            e.	The right to object to processing;
                <br/>
            You have the right to object to our processing of your personal data on grounds relating to your particular situation, but only to the extent that the legal basis for the processing is that the processing is necessary for: the performance of a task carried out in the public interest or in the exercise of any official authority vested in us; or the purposes of the legitimate interests pursued by us or by a third party. If you make such an objection, we will cease to process the personal information unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defense of legal claims.
            </p>
            <p>
            f.	The right to data portability;
            <br/>
            You have the right to receive your personal data from us in a structured, commonly used and machine-readable format. However, this right does not apply where it would adversely affect the rights and freedoms of others.
            </p>
            <p>
            g.	The right to complain to a supervisory authority; and
            <br/>
            If you consider that our processing of your personal information infringes data protection laws, you have a legal right to lodge a complaint with a supervisory authority responsible for data protection. You may do so in the EU member state of your habitual residence, your place of work or the place of the alleged infringement.
            </p>
            <p>
            h.	The right to withdraw consent.
            <br/>
            To the extent that the legal basis for our processing of your personal information is consent, you have the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.
            </p>
            <p>
            You may exercise each or all of these rights by contacting our compliance team at  
            <a href="mailto:info@mbrsc.ae" className="ml2">info@mbrsc.ae</a>,  or by sending a letter to MBRSC, Khawaneej Area P.O. Box: 211822 Dubai, UAE.
            </p>
        </div>
    </main>
  </Layout>
)

export default PrivacyPage
